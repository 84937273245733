 
.datasheet-container {    
  margin: 24px;
   height: calc(100% - 200px);
  overflow: auto;
}

.title-column svg { 
  width: 24px !important;
  height: 24px !important;
}

.dsg-container {
  height: auto!important;
  overflow: initial;
}

.dsg-active-cell {
  scroll-margin: 300px;
}