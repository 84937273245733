 
@import url("https://fonts.googleapis.com/css2?family=Archivo&display=swap"); 

* { 
    padding: 0; 
    margin: 0;
    font-family: Archivo;

    --fmc-red: #ee2e30;

    ::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track {
		background: #fcfcfc;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: lightgray;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: gray;
	}

	::-webkit-scrollbar-corner {
		background: rgba(0, 0, 0, 0);
	}
}
 

html, body  {
    min-height: 100% !important;
    height: 100%;
}
 

.main-layout {
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    grid-template-rows: 100%;

    width: 100vw;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
}  
  
@keyframes fade-in {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}